<template>
  <tooltip-view>
    <template v-slot:action>
      <div class="fill-height overflow-hidden" :class="{ visitTimeFinish: isFinish() }">
        <div class="fill-height full-width position-absolute" :style="'opacity: 0.1;background:'+status.color"></div>
        <v-row no-gutters>
          <v-col cols="auto">
            <visit-status
                :status="status"
                :isPaidFull="event.visit.paySummary.isPaidFull"
                :isSecond="event.visit.isSecond"
                :isComment="!$tools.isEmpty(event.visit.user.userProfile.comment) || !$tools.isEmpty(event.visit.comment)"
                xsmall/>
          </v-col>
          <v-col cols="auto" class="black--text text-no-wrap text-overflow pl-1 pr-1">
            <v-sheet class="d-flex align-center" height="20" color="transparent">
              {{$tools.time(event.start)}}
            </v-sheet>
          </v-col>
          <v-col class="black--text text-no-wrap text-overflow">
            <v-sheet class="d-flex align-center" height="20" color="transparent">
              {{event.visit.user.lastName != null ? event.visit.user.lastName : ''}}
              {{event.visit.user.firstName != null ? event.visit.user.firstName : ''}}
              {{event.visit.user.secondName != null ? event.visit.user.secondName : ''}}
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:content>
      <label-view v-if="!$tools.isEmpty(event.visit.user.firstName)" title="ФИО">
        <template v-slot:content>
          {{ event.visit.user.lastName }} {{ event.visit.user.firstName }} {{ event.visit.user.secondName }}
        </template>
      </label-view>
      <label-view v-if="!$tools.isEmpty(event.visit.user.userProfile.resAddrStreet)" title="Адрес">
        <template v-slot:content>
          {{ event.visit.user.userProfile.resAddrStreet }}
        </template>
      </label-view>
      <label-view title="Номер карты">
        <template v-slot:content>
          <div v-if="!$tools.isEmpty(event.visit.user.userProfile.medCardNumber)" v-html="$tools.nl2br(event.visit.user.userProfile.medCardNumber)"></div>
          <div v-if="$tools.isEmpty(event.visit.user.userProfile.medCardNumber)" v-html="$tools.nl2br(event.visit.user.id)"></div>
        </template>
      </label-view>
      <label-view v-if="!$tools.isEmpty(event.visit.user.userProfile.birth)" title="Дата рождения">
        <template v-slot:content>
          <div class="d-flex">
            <div class="mr-1" v-html="$tools.nl2br($tools.dateTimeFormat(event.visit.user.userProfile.birth, 'DD.MM.YYYY'))"></div>
            <div  v-html="'('+$tools.nl2br($tools.dateYears(event.visit.user.userProfile.birth,'YYYY-MM-DD'))+')'"></div>
          </div>
        </template>
      </label-view>
      <label-view v-if="!$tools.isEmpty(event.visit.user.userProfile.comment)" title="Комментарий к клиенту">
        <template v-slot:content>
          <div v-html="$tools.nl2br(event.visit.user.userProfile.comment)"></div>
        </template>
      </label-view>
      <label-view v-if="!$tools.isEmpty(event.visit.comment)" title="Комментарий к визиту">
        <template v-slot:content>
          <div v-html="$tools.nl2br(event.visit.comment)"></div>  
        </template>
      </label-view>
    </template>
  </tooltip-view>
</template>

<script>
import moment from "moment";
import VisitStatus from "@/components/VisitStatus";
import TooltipView from "@/components/TooltipView";
import LabelView from "@/components/LabelView";
export default {
  components: {LabelView, TooltipView, VisitStatus},
  props: ['event', 'statuses'],
  computed:{
    status(){
      return this.$tools.findById(this.statuses, this.event.visit.status);
    }
  },
  methods: {
    isFinish() {
      if (moment(this.event.end).diff(moment(), 'minutes')<0) {
        return true
      }  
    }
  }
}
</script>